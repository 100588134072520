/**
 * @kind cache
 * @class ContactFieldsInfo
 * @description Used to list all contact fields info
 * @usage Grape.cache.fetch('ContactFieldsInfo', function(data) { });
 */
export default {
	name: 'ContactFieldsInfo',
	options: {
		table: 'v_contact_fields_info',
		schema: 'contacts'
	}
};
