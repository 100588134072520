
import template from './contact_component.html';
import ContactModel from '../../lib/models/contact_model'

/**
 * @kind component
 * @class ContactViewModel
 * @description This is contact ko component for the display and modification of contact data
 */
class ContactViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contactModel = ko.observable(new ContactModel(params.contact));
		this.displayContext = ko.observable(params.displayContext || 'page');
	}

	async saveContact()
	{
		if (!this.contactModel().values()?.refnr())
		{
			let confirm = await Grape.alerts.confirm({ type:'warning', title:'Create New Contact', message: 'Create new contact?' });
			if (!confirm)
				return
		} else {
			let confirm = await Grape.alerts.confirm({ type:'warning', title:'Save Contact', message: 'Save Changes?' });
			if (!confirm)
				return
		}

		let res = await this.contactModel().save();
		if (res.status === 'OK')
		{
			Grape.alerts.alert({ type:'success', title:'Contact Saved', message:'Successfully saved contact.' });
			this.contactModel().load(res.refnr);
		}
		else
			Grape.alerts.alert( {type:'danger', title:'Contact Save Error', message:res.message });
	}

	async clear()
	{
		let confirm = await Grape.alerts.confirm({ type:'warning', title:'Clear', message: 'Clear and lose any changes?' });
		if (!confirm)
			return
		this.contactModel().clear();
	}
}

export default {
	name: 'contact-component',
	viewModel: ContactViewModel,
	module_type: 'ko',
	template: template
};