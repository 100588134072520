import template from './search-dropdown.html';

/**
* @class SearchDropdownComponentViewModel
* @component name - search-dropdown
* @constructor
* @param {object[]} [availableItems=[]]- optional param passed to the constructor to initialize availableItems property
* @param {object[]} selectedItem- selected item
* @param {text} cacheName - name of the cache to use if no available_items is passed
* @param {text} nameField - name of the cache to use if no available_items is passed
* @param {text} [title] - title of the dropdown
*/
class SearchDropdownComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.availableItems = ko.observableArray();
		this.paramItems = ko_helper.safe_observableArray(params.availableItems);
		this.filteredItems = ko.observableArray([]);
		this.selectedItem = ko_helper.safe_observable(params.selectedItem);
		this.title = ko.observable(params.title || 'Select item...');
		this.cacheName = ko.observable(params.cacheName);
		this.nameField = ko.observable(params.nameField);
		this.searchText = ko.observable();
		this.allowBlank = ko.observable(params.allowBlank??=true);

		this.searchText.subscribe((newValue) => this.search(newValue) );
		this.availableItems.subscribe(() => this.search('') );
		this.paramItems.subscribe((newValue) => this.availableItems(newValue) );

		this.filteredItems.subscribe((newValue=>{
			if (this.allowBlank() === false){
				if (newValue.length>0)
					this.selectedItem(newValue[0]);
			}
		}));

		this.init();
	}

	async init ()
	{
		let sellers;

		if (!this.paramItems() || this.paramItems().length <= 0)
			sellers = await Grape.cache.fetch(this.cacheName());
		else
			sellers = this.paramItems();

		this.availableItems(sellers || [])
	}

	search (newValue)
	{
		let searchTerm = newValue.toLowerCase();
		let items = this.availableItems();
		let matchedsellers = items.filter(loc => loc[this.nameField()].toLowerCase().includes(searchTerm));

		this.filteredItems(matchedsellers);
	}

	itemClick (row)
	{
		console.log(row);
		this.searchText('');

		if (row === this.selectedItem())
			this.selectedItem('');
		else
			this.selectedItem(row);
	}
}

export default {
	name: 'search-dropdown',
	viewModel: SearchDropdownComponentViewModel,
	module_type: 'ko',
	template: template
}
