
import template from './new-contact-create.html';
import ContactModel from '../../lib/models/create-contact-model.js'

/**
 * @kind component
 * @class ContactCreateViewModel
 * @description This is contact ko component for the create contacts
 */
class ContactCreateViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contactModel = ko.observable(new ContactModel(params));
		this.displayContext = ko.observable(params.displayContext || 'page');
	}

	async saveContact()
{
		let confirm = await Grape.alerts.confirm({ type:'warning', title:'Create New Contact', message: 'Create new contact?' });
		if (!confirm)
			return

		let res = await this.contactModel().save();
		if (res.status === 'OK')
		{
			Grape.alerts.alert({ type:'success', title:'Contact Saved', message:'Successfully saved contact.' });
			this.contactModel().load(res.refnr);
		}
		else
			Grape.alerts.alert( {type:'danger', title:'Contact Save Error', message:res.message });
	}

	async clear()
	{
		let confirm = await Grape.alerts.confirm({ type:'warning', title:'Clear', message: 'Clear and lose any changes?' });
		if (!confirm)
			return
		this.contactModel().clear();
	}
}

export default {
	name: 'contact-create',
	viewModel: ContactCreateViewModel,
	module_type: 'ko',
	template: template
};