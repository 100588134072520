class CreateContactModel
{
	constructor(contact)
	{
		this.contact = contact;
		this.values = ko.observable();
		this.fields_info = ko.observableArray();
		this.contact_types = ko.observable([]);
		this.select_contact_type = ko.observable();
		this.type_fields = ko.observableArray([]);

		this.fields_info.subscribe((newValue) => {
			if (this.fields_info().length > 0)
				this.createValuesTemplate();
		});

		this.get_fields_info().then(async (fields_info) => {
			await this.load_cache();
			this.fields_info(fields_info);
		});

		this.select_contact_type.subscribe((value) => {
			this.hide_fields();
		});
	}

	hide_fields()
	{
		this.type_fields(this.contact_types().filter(item => item.name === this.select_contact_type()).map(x => x.data.field_tables));
		for (var i = 0; i < this.fields_info().length; i++)
		{
			let type_status = this.type_fields().toString().includes(this.fields_info()[i].category.toString());
			this.fields_info()[i].field_hide = type_status;
		};

		let field_info_clean = this.fields_info().slice(0);
		this.fields_info([]);
		this.fields_info(field_info_clean);
	}

	clear()
	{
		this.contact({});
	}

	async load(refnr)
	{
		let contact = await Grape.fetches.getJSON(`api/contacts/?refnr=${encodeURI(refnr)}`);
		this.contact(contact);
	}

	async load_cache()
	{
		await Grape.cache.fetch('ContactTypesLookup', (ct) => {
			if (ct)
			{
				let types  = ct.map((x) => { return { name: x.name, data: x.data }});
				this.contact_types(types);
			}
		});
	}

	async save()
	{
		let payload = ko.mapping.toJS(this.values);
		payload.contact_type = this.select_contact_type();
		let res = await Grape.fetches.postJSON(`api/contacts/upsert/`, payload);
		return res;
	}

	async get_fields_info()
	{
		return await Grape.cache.get('ContactFieldsInfo');
	}

	createValuesTemplate()
	{
		let fields_info = this.fields_info();
		let values = {refnr:'', contact_uuid:'', contact_type_lookup_id:''};
		for (let category of fields_info)
		{
			let fields = {}
			for (let field of category.fields)
			{
				fields[field.field_name] = '';
			}
			values[category.category] = fields;
		}
		this.values(ko.mapping.fromJS(Object.assign(values ||{})));
	}
}

export default CreateContactModel;