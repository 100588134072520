import template from './type-edit.html';

class ContactTypeEditVM
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.type = ko.observable();
		this.type_lookup_id = ko.observable(null);
		this.type_lookup_value_id = ko.observable(null);
		this.type_name = ko.observable();
		this.type_description = ko.observable();
		this.selection_field_tables = ko.observableArray([]);
		this.type_field_table = ko.observableArray([]);
		this.builtin = ko.observable(false);
		this.idx = ko.observable();
	}

	load() 
	{
		if(typeof this.type() != 'undefined')
		{
			this.type_lookup_id(this.type().lookup_id);
			this.type_lookup_value_id(this.type().lookup_value_id);
			this.type_name(this.type().name);
			this.type_description(this.type().description);
			this.type_field_table(this.type().data.field_tables);
			this.builtin(this.type().data.builtin);
			this.idx(this.type().idx);
		}
	}

	async load_field_tables ()
	{		
		await Grape.cache.fetch('ContactFieldsInfo', (ct) => {
			let fields  = ct.map((x) => {
				return x.category
			});
		
			this.selection_field_tables(fields);
			console.log(this.selection_field_tables());
		});

	}

	async save_type()
	{
		let type = {
			lookup_id: this.type_lookup_id(),
			lookup_value_id: this.type_lookup_value_id(),
			name: this.type_name(),
			description: this.type_description(),
			field_tables: this.type_field_table(),
			idx: this.idx()
		}

		if (typeof this.type_name() === 'undefined')
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'Name Required'});
			return;
		}
		else if (typeof this.type_description() === 'undefined')
		{
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'Description Required'});
			return;
		}

		try
		{
			let result = await Grape.fetches.postJSON('api/contacts/types', {'contact_types': [type]});
			if (result.status != 'ERROR')
			{
				this.close_dialog();
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
		}
	}

	close_dialog()
	{
		this.dialog.close();
	}
}

class ContactTypeEditDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ContactTypeEditVM(this);
	}

	async init ()
	{
		this.viewModel.type(this.bindings.type);
		await this.viewModel.load_field_tables();
		this.viewModel.load();
		document.title = 'Contacts Type - Edit';
	}

	teardown ()
	{
	}
}

export default {
	name: 'ContactsTypeEdit',
	dialog_class: ContactTypeEditDialog,
	template: template,
	provider: 'ps'
}