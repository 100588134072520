import template from './detail.html';

class ContactDetailVM
{
	constructor (page)
	{
		this.page = page;
		this.selected_contact = ko.observable(this.page.bindings.contact);
	}

	async pickerUpdateData(newValue){
		return new Promise((resolve, reject)=>{
			if (!newValue || !newValue.trim())
				return;
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(async()=>{
			resolve((await Grape.fetches.getJSON(`api/contacts/search/?searchtext=${encodeURIComponent(newValue.trim().replace(/(\s\s+|\+|@|\.)/g, ' ').trim().split(' ').join(':* & ')+':*')}`)).contacts||'Select Contact...');
		}, 250)});
	}

	pickerDisplayValue(contact){ // TODO this is awful do it better, no html and dynamic etc...
		return `<div><div style="display:inline-block;border:1px solid lightgrey;padding:0.1em;border-radius:6px"><b>Refnr: </b>${contact?.refnr}</div> <div style="display:inline-block;border:1px solid lightgrey;padding:0.1em;border-radius:6px"><b>Name: </b>${contact?.personal_detail?.name}</div><div style="display:inline-block;border:1px solid lightgrey;padding:0.1em;border-radius:6px"><b>Email: </b>${contact?.contact_detail?.email}</div><div style="display:inline-block;border:1px solid lightgrey;padding:0.1em;border-radius:6px"><b>Phone: </b>${contact?.contact_detail?.phone}</div></div>` || 'Select Contact...';
	}
}

class ContactDetailPage
{
	constructor (bindings, element, page)
	{
		this.bindings = bindings;
		this.viewModel = new ContactDetailVM(this);
	}

	async init ()
	{
		document.title = 'Contacts - Detail';
	}

	teardown ()
	{
	}
}

export default {
	route: '[/ui/crm/contacts/]detail',
	page_class: ContactDetailPage,
	template: template
}
