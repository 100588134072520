import template from './ko-contact-notes.html';

class ContactNotesPanelViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contact = ko_helper.safe_observable(params.contact);
		this.name = ko_helper.safe_observable(params.name);
		this.notes = ko.observable();

		this.contact.subscribe((newValue) => {
			this.get_notes(newValue);
		});
	}

	async init()
	{
		if (this.contact())
			this.get_notes(this.contact());
	}

	async get_notes(contact)
	{
		// api to get notes
	}
}


export default {
	name: 'ko-contact-notes-panel',
	viewModel: ContactNotesPanelViewModel,
	module_type: 'ko',
	template: template
}