import template from './contact-create.html';

class ContactCreateViewModel
{
	constructor (page)
	{
		this.page = page;
		this.contact = ko.observable(this.page.bindings.contact);
	}

	btn_back_click()
	{
		Grape.navigate('[/ui/crm/contacts/]search');
	}
}

class ContactCreatePageClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ContactCreateViewModel(this);
		this.name = 'ContactCreatePageClass';
		document.title = 'Create Contact';
	}
}

export default {
	route: '[/ui/contacts/]create',
	page_class: ContactCreatePageClass,
	template: template
}