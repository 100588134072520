import template from './contact-account.html';

class ContactAccountViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contact = ko_helper.safe_observable(params.contact);

		this.contact.subscribe((val) => {
			this.get_queries(val);
		});

		this.init();
	}

	async init()
	{

	}

	async get_queries()
	{

	}
}


export default {
	name: 'ko-contact-account',
	viewModel: ContactAccountViewModel,
	module_type: 'ko',
	template: template
}