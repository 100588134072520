import template from './search.html';

class ContactSearchVM
{
	constructor (page)
	{
		this.page = page;
		this.contact_types = ko.observableArray();
		this.selected_type = ko.observable();
		this.selected_types = ko.observable();
		this.search_text = ko.observable(localStorage.getItem('last_contact_search_string') || '');
		this.loading = ko.observable(false);
		this.results = ko.observableArray([]);
		this.search_timeout;
		this.fields = ko.observableArray([]);
		this.visible_fields = ko.observableArray([]);
		this.selected_tab = ko.observable('contact_search');
		this.generalFields = ko.observable();
		this.groupedFields = ko.observable();

		this.search_text.subscribe((newValue) => {
			if (newValue === '')
				this.results([]);

			this.on_search_typing(newValue);
			localStorage.setItem('last_contact_search_string', newValue);
		});

		this.fields.subscribe((newValue)=>{
			localStorage.setItem('contactSearchFields', JSON.stringify(ko.mapping.toJS(newValue)));
		});

		this.selected_tab.subscribe((newVal) => {
			if (newVal === 'contact_search')
				this.selected_types(['Search']);
			else if (newVal === 'contact_filter')
				this.selected_types(['Filter']);
			else if (newVal === 'contact_create')
				this.selected_types(['Create Contact']);
		});

		this.selected_type.subscribe((val) => {
			this.page.populate_fields();
			this.btn_search_click();
		});

		this.field_value_formatter = this.field_value_formatter.bind(this);
		this.view_contact = this.view_contact.bind(this);
	}

	switch_tabs (data, event)
	{
		let tabs = document.querySelectorAll('#contacts-nav li');

		tabs.forEach((tab) => {
			tab.classList.remove('active');
		});

		event.currentTarget.classList.add('active');

		this.selected_tab(event.currentTarget.getAttribute('data-tabname'));
	}

	reset_fields()
	{
		localStorage.removeItem('contactSearchFields');
		this.page.populate_fields();
	}

	async on_search_typing(newValue)
	{
		clearTimeout(this.search_timeout);
		this.search_timeout = setTimeout(()=>{
			this.btn_search_click();
		}, 250);
	}

	async btn_search_click()
	{
		let contact_type = this.selected_type().name;
		let search_text = this.search_text();
		if (!search_text || !search_text.trim())
			return;
		// TODO search_text cleanup/formatting
		this.loading(true);
		await Grape.fetches.getJSON(`api/contacts/search/?contact_type=${encodeURIComponent(contact_type)}&searchtext=${encodeURIComponent(search_text.trim().replace(/[+.()@&:*]/g, ' ').replace(/\s\s+/g, ' ').trim().split(' ').join(':* & ')+':*')}`).then((res) => {
			this.results(res.contacts || []);
			this.loading(false);
		});
	}

	view_contact(contact)
	{
		Grape.navigate('[/ui/contacts/]dashboard', {contact});
	};

	async field_value_formatter({name, data})
	{
		let value = '';
		let keys = name.split('.');
		if (typeof data[keys[0]] != 'undefined')
		{
			if (keys.length === 1)
				value = data[keys[0]];
			else
				value = data[keys[0]][keys[1]];
		}

		value ??= '';
		if (typeof value !== 'string')
			value = JSON.stringify(value);
		let rg = new RegExp(`(${this.search_text().replace(/[-[\]{}()*+?.,\\^$|]/g, "\\$&").trim().split(' ').join('|')})`, 'ig');
		return value.replace(rg, '<span style="background-color:yellow">$1</span>');
	}
}

class ContactSearchPage
{
	constructor (bindings, element, page)
	{
		this.bindings = bindings;
		this.viewModel = new ContactSearchVM(this);
		this.timer = null;
		this.fields_info = [];
	}

	async init ()
	{
		document.title = 'Contacts - Search';
		this.get_contact_types();
		this.get_fields_info();
		this.populate_fields();
	}

	get_fields_info()
	{
		this.fields_info = Grape.cache.get('ContactFieldsInfo');
	}

	async get_contact_types()
	{
		let types = await Grape.cache.get('ContactTypesLookup');
		this.viewModel.contact_types(types);
	}

	async populate_fields()
	{
		let fields_info = await this.fields_info;

		let selected_contact = this.viewModel.selected_type();
		let field_tables = selected_contact.data.field_tables;

		// Filter the fields_info to only include relevant categories
		let filtered_fields = fields_info.filter(category => field_tables.includes(category.category));

		let generalFields = [];
		let groupedFields = [];

		/*let fields = [{name: 'Ref#', path: 'refnr', visible: ko.observable(true)}];
		for (let category of filtered_fields) {
			for (let field of category.fields) {
				fields.push({name: field.label, path: `${category.category}.${field.field_name}`, visible: ko.observable(true)});
			}
		}*/

		for (let category of fields_info) {
			if (category.category === 'general') {
				for (let field of category.fields) {
					generalFields.push({
						name: field.label,
						path: `${category.category}.${field.field_name}`,
						visible: ko.observable(true)
					});
				}
			} else if (field_tables.includes(category.category)) {
				let categoryFields = category.fields.map(field => ({
					name: field.label,
					path: `${category.category}.${field.field_name}`,
					visible: ko.observable(true)
				}));
				groupedFields.push({
					category: category.display_name,
					fields: categoryFields
				});
			}
		}

		this.viewModel.generalFields(generalFields);
		this.viewModel.groupedFields(groupedFields);

		// if localstorage modify order and visibility
		/*try {
			let storedFields = JSON.parse(localStorage.getItem('contactSearchFields'));
			if (storedFields)
			{
				let order = [];
				let visMap = {};
				for (let field of storedFields)
				{
					visMap[field.name] = field.visible;
					order.push(field.name);
				}
				fields.sort( (a, b) => {
					let A = a['name'], B = b['name'];
					a.visible(visMap[A]);

					if (order.indexOf(A) > order.indexOf(B))
						return 1;
					else
						return -1;
				});
			}
		} catch(err) {
			//do nothing
		}*/
		//this.viewModel.fields(fields);
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/ui/crm/contacts/]search',
	page_id: 'sidebar-subpage',
	page_class: ContactSearchPage,
	template: template
}
