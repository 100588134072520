import template from './reports.html';

class ContactReportsVM
{
	constructor (page)
	{
		this.page = page;
	}
}

class ContactReportsPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new ContactReportsVM(this);
		this.bindings = bindings;
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Contacts - Reports';
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/ui/crm/contacts/]reports',
	page_id: 'sidebar-subpage',
	page_class: ContactReportsPage,
	template: template
}
