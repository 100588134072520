import template from './ko-contact-details.html';

class ContactDetailsViewModel
{
	constructor(params)
	{
		this.params = params;
		this.contact = ko_helper.safe_observable(params.contact);
		this.contact_type_name = ko_helper.safe_observable(params.contact);
		this.name = ko_helper.safe_observable(params.name);
		this.contact_type = ko.observable();
		this.mappedValues = ko.observableArray([]);
		this.contact_uuid = ko.observable(this.contact.contact_uuid);

		this.contact.subscribe((val) => {
			if (val)
			{
				let uuid = this.contact().contact_uuid;
				this.contact_uuid(uuid);
			}

		})

		this.init();
	}

	async init()
	{
		/*if (this.contact())
			this.mapContactValues(this.contact());*/
	}

	/*mapContactValues(contact)
	{
		if (!contact) return;
		this.contact_type(contact.contact_type || '');

		let filteredCategories = Object.keys(contact).filter(key => 
			key !== 'contact_uuid' && key !== 'refnr' && typeof contact[key] === 'object'
		);

		let mappedValues = filteredCategories.map(category => {
			return {
				category: category,
				displayName: this.formatDisplayName(category),
				fields: Object.keys(contact[category]).map(field => {
					return {
						fieldName: field,
						label: this.formatDisplayName(field),
						value: contact[category][field] || 'N/A'
					};
				})
			};
		});

		this.mappedValues(mappedValues);
	}*/

	/*formatDisplayName(name) {
		return name.charAt(0).toUpperCase() + name.slice(1).replace('_', ' ');
	}*/
}


export default {
	name: 'ko-contact-details',
	viewModel: ContactDetailsViewModel,
	module_type: 'ko',
	template: template
}
