
import ContactSearchPage from './contacts/search/search.js';
import ContactDetailPage from './contacts/detail/detail.js';
import ContactReportPage from './contacts/reports/reports.js';
import ContactsTypes from './contacts_types/contacts-types.js';
import ContactsDashboard from "./contacts/dashboard/contacts-dashboard.js";
import ContactCreate from './contacts/contact-create/contact-create.js';

export default [

	ContactSearchPage,
	ContactDetailPage,
	ContactReportPage,
	ContactsTypes,
	ContactsDashboard,
	ContactCreate
];
