import template from './contacts-dashboard.html';

class ContactsDashboardViewModel
{
	constructor (page)
	{
		this.page = page;
		this.contact = ko.observable(this.page.bindings.contact);

		this.contact_check();
	}

	contact_check ()
	{
		
		if (typeof this.contact() === 'undefined')
		{
			let contact = localStorage.getItem('last_visited_contact');
			if (contact)
			{
				this.contact(JSON.parse(contact));
			}
			else
				Grape.navigate('[/ui/crm/contacts/]search', {});
		}
		else
		{
			localStorage.setItem('last_visited_contact', JSON.stringify(this.contact()));
		}
	}

	btn_back_click()
	{
		Grape.navigate('[/ui/crm/contacts/]search');
	}
}

class ContactsDashboardPageClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ContactsDashboardViewModel(this);
		this.name = 'ContactsDashboardPageClass';
		document.title = 'Contacts Dashboard';
	}
}

export default {
	route: '[/ui/contacts/]dashboard',
	page_class: ContactsDashboardPageClass,
	template: template
}