// COMPONENTS
import contact_search_dropdown from './search-dropdown/search-dropdown.js';
import contact_component from './contacts/contact_component.js';

// TABS COMPONENTS
import contact_info from "./contacts-dashboard-tabs/ko-contact-info.js";
import contact_create from './new-contact/new-contact-create.js';
import contact_filter from './filter-contacts/filter-contacts.js';
import contact_sales from './contact-sales/contact-sales.js';
import contact_notes from './contact-notes/contact-notes.js';
import contact_account from './contact-accounts/contact-account.js';

// PANELS COMPONENTS
import contact_details from "./contacts-dashboard-panels/ko-contact-details.js";
import contact_details_panel from './contacts-dashboard-panels/contact-details/contact-details.js';
import contact_notes_panel from './contacts-dashboard-panels/contact-notes/ko-contact-notes.js';

// TODO: create and import card/panel components in for in the tabs

export default [
	contact_search_dropdown,
	contact_component,
	contact_info,
	contact_details,
	contact_create,
	contact_filter,
	contact_details_panel,
	contact_account,
	contact_sales,
	contact_notes,
	contact_notes_panel
];
