
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';
import appcaches from './caches/index.js';
import plugins from './plugins/index.js';

import package_json from '../package.json';

window.Grape.registry.addRegister('tabs_dashboard.contacts_page');
/* these components hold the card/panels components for a specific tab */
window.Grape.registry.addItem('tabs_dashboard.contacts_page', 'Overview', { component: 'ko-contact-info'});
window.Grape.registry.addItem('tabs_dashboard.contacts_page', 'Change Details', { component: 'contact-component'});
window.Grape.registry.addItem('tabs_dashboard.contacts_page', 'Queries', { component: 'ko-contact-queries'});
window.Grape.registry.addItem('tabs_dashboard.contacts_page', 'Notes', { component: 'ko-contact-notes'});
window.Grape.registry.addItem('tabs_dashboard.contacts_page', 'Sales', { component: 'ko-contact-sales'});
window.Grape.registry.addItem('tabs_dashboard.contacts_page', 'Account', { component: 'ko-contact-account'});
window.Grape.registry.addItem('tabs_dashboard.contacts_page', 'Shipments', { component: 'ko-shipments'});

window.Grape.registry.addRegister('info_tab.panels');
/* these components are to be used inside the specific tab as cards/panels */
window.Grape.registry.addItem('info_tab.panels', 'Contact Details', { component: 'ko-contact-details'});

(function() {
	window.Grape.modules.push(package_json);

	Grape.cache.registerCaches(appcaches);
	Grape.component.registerComponents(components);
	Grape.dialog.registerDialogs(dialogs);
	Grape.pages.registerPages(pages);
	Grape.plugins.registerPlugins(plugins);
})();
