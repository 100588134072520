import template from './ko-contact-info.html';

class ContactInfoViewModel
{
	constructor (params)
	{
		this.params = params;
		this.contact = ko_helper.safe_observable(params.contact);
		this.panels = ko.observableArray([]);

		this.init();
	}

	async init ()
	{
		let map = Grape.registry.getRegister('info_tab.panels');
		if (map && map.size)
		{
			this.panels([...map.keys()].map(key => ({
				tab_component_name: key,
				tab_component: map.get(key)
			})));
		}
	}
}

export default {
	name: 'ko-contact-info',
	viewModel: ContactInfoViewModel,
	module_type: 'ko',
	template: template
}
