import template from './contact-edit.html';

class ContactEditVM
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.selected_contact = ko.observable(this.dialog.bindings.contact);
	}

	close_dialog() {
		this.dialog.close();
	}
}

class ContactEditDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ContactEditVM(this);
	}

	async init ()
	{
		document.title = 'Contacts - Edit';
	}

	teardown ()
	{
	}
}

export default {
	name: 'ContactEdit',
	dialog_class: ContactEditDialog,
	template: template,
	provider: 'ps'
}